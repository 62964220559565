var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "p-giftList",
      class: { "p-giftList--none": !_vm.hasPresent },
    },
    [
      _c(
        "div",
        {
          staticClass: "c-section c-section--fluid c-section--mb0",
          class: { "c-section--white01Bg": _vm.hasPresent },
        },
        [
          _c("p", { staticClass: "p-giftList__description" }, [
            _vm._v(
              " 獲得したプレゼントはオンラインストアでご利用いただけます。 "
            ),
          ]),
        ]
      ),
      _vm.presents.length
        ? _c("div", [
            _c(
              "div",
              { staticClass: "c-section c-section--fluid c-section--mb0" },
              [
                _c(
                  "div",
                  { staticClass: "c-cards c-cards--tiled" },
                  _vm._l(_vm.presents, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "c-cards__inner" },
                      [
                        _c("CouponCard", {
                          attrs: {
                            href: _vm.generateUrl(item.id),
                            src: item.reward.image_url,
                            title: item.reward.name,
                            subtitle: _vm.getCategory(item.reward.type),
                            noticed: item.is_new,
                            tiled: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.markAsRead(item.id)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.nextPageUrl
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "c-section c-section--fluid c-section--white01Bg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "p-giftList__actions" },
                          [
                            _c(
                              "Button",
                              {
                                attrs: {
                                  size: "sm",
                                  "style-type": "tertiary",
                                  fluid: "",
                                },
                                on: { click: _vm.loadMore },
                              },
                              [_vm._v(" プレゼントをもっと見る ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ])
        : !_vm.$_loading_state
        ? [
            _c("Empty", {
              attrs: {
                "icon-name": "present",
                text: "獲得したプレゼントはありません",
                subtext: "マイルを貯めてお得なプレゼントを\nゲットしよう",
              },
            }),
            _c(
              "div",
              { staticClass: "p-giftList__emptyActions" },
              [
                _c(
                  "Button",
                  {
                    attrs: {
                      "style-type": "primary",
                      fluid: "",
                      href: _vm.homeUrl,
                    },
                  },
                  [_vm._v(" ホームでマイルを貯める ")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }