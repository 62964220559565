<template>
  <div class="p-giftList" :class="{ 'p-giftList--none': !hasPresent }">
    <div
      class="c-section c-section--fluid c-section--mb0"
      :class="{ 'c-section--white01Bg': hasPresent }">
      <p class="p-giftList__description">
        獲得したプレゼントはオンラインストアでご利用いただけます。
      </p>
    </div>

    <div v-if="presents.length">
      <div class="c-section c-section--fluid c-section--mb0">
        <div class="c-cards c-cards--tiled">
          <div v-for="item in presents" :key="item.id" class="c-cards__inner">
            <CouponCard
              :href="generateUrl(item.id)"
              :src="item.reward.image_url"
              :title="item.reward.name"
              :subtitle="getCategory(item.reward.type)"
              :noticed="item.is_new"
              tiled
              @click="markAsRead(item.id)" />
          </div>
        </div>
        <!-- /.c-cards.c-cards--tiled -->

        <div
          v-if="nextPageUrl"
          class="c-section c-section--fluid c-section--white01Bg">
          <div class="p-giftList__actions">
            <Button size="sm" style-type="tertiary" fluid @click="loadMore">
              プレゼントをもっと見る
            </Button>
          </div>
        </div>
      </div>
    </div>

    <template v-else-if="!$_loading_state">
      <Empty
        icon-name="present"
        text="獲得したプレゼントはありません"
        :subtext="'マイルを貯めてお得なプレゼントを\nゲットしよう'" />
      <div class="p-giftList__emptyActions">
        <Button style-type="primary" fluid :href="homeUrl">
          ホームでマイルを貯める
        </Button>
      </div>
    </template>
  </div>
</template>

<script>
import { RewardType, SystemDialogMessage } from '@/constants/enums';

export default {
  components: {
    CouponCard: () => import('@/components/mypage/CouponCard.vue')
  },

  data: () => ({
    presents: [],
    nextPageUrl: null
  }),

  computed: {
    hasPresent() {
      return this.presents.length > 0;
    },

    homeUrl() {
      return this.$customUrlScheme.page('root/home/1');
    }
  },

  created() {
    this.fetchList();
  },

  methods: {
    async fetchList() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('present').getPresents(
          this.$utilities.getUrlSearch(this.nextPageUrl)
        );
        this.presents.push(...data.data);
        this.nextPageUrl = data.next_page_url;
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    async loadMore() {
      if (this.$_loading_state) return;
      await this.fetchList();
    },

    /**
     * プレゼントを既読にする
     *
     * NOTE: APIでのデータ更新ではなく、あくまでUI要件を満たすための対応
     */
    markAsRead(presentId) {
      const present = this.presents.find((present) => present.id === presentId);
      if (!present) return;
      present.is_new = false;
    },

    generateUrl(presentId) {
      return this.$customUrlScheme.page(
        'detail',
        `url=/mypage/gift/detail/${presentId}`
      );
    },

    getCategory(rewardType) {
      return RewardType.getTypeName(rewardType);
    }
  }
};
</script>
